import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import SubscribeList from './SubscribeList';

function SubscribeListContainer({ onChange, subscribedLists }) {
  const [lists, setLists] = useState([]);
  const csrftoken = Cookies.get('csrftoken');


  // retrieve lists
  useEffect(() => {
    
    async function fetchSubscriptionTags() {
      try {
        const response = await fetch("/mailinglists/_api/subscription-tags/", {
          method: "GET", 
          headers: {
            "Content-Type": "application/json", 
            "X-CSRFToken": csrftoken,
          }
        });
        const data = await response.json();
        setLists(data);
        
      } catch (error) {
        console.log("API Error: " + error);
        
      }
    }

    fetchSubscriptionTags();
    
  }, []);


  return (
    <>
      {lists.map((list, index) => (
        <SubscribeList
          key={list.title}
          title={list.title}
          tag={list.tag}
          description={list.description}
          onChange={onChange}
        />
      ))}
    </>
  )
}

export default SubscribeListContainer;